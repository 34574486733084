
import { SuViewCtrlInjectIsMobile } from '@thestarweb/ui';
import { Options, Vue } from 'vue-class-component';


@Options({
})
export default class Page extends Vue {
	@SuViewCtrlInjectIsMobile readonly isMoblie!: any;
	mounted(){
		console.log(this.isMoblie);
	}
	private get gemLvList(){
		let list=[];
		for (let i = 15; i <= 25; ++i) {
			list.push({value:i});
		}
		return list;
	}
	private gemType="normal";
	private gemLv=15;
	private gemRank:number|string=1250;
	private get_pr(lv:number):number{
		if(lv==20||lv==25) return 75;
		return 30;
	}
	public get gemLvTank():number[]{
		let temp:number[]=[];console.log(this.gemRank);
		temp[this.gemLv]=parseInt(this.gemRank as string);
		for(let i=this.gemLv-1;i>=15;i--){
			temp[i]=temp[i+1]-this.get_pr(i+1);
		}
		for(let i=this.gemLv+1;i<=25;i++){
			temp[i]=temp[i-1]+this.get_pr(i);
		}
		return temp;
	}
	private get gemGrade():string{
		let out_dj="T";
		if(this.gemType=="normal"){
			if(this.gemLvTank[15]>=1310) out_dj="O";//Outstanding （完美）
			else if(this.gemLvTank[15]>=1280) out_dj="E";//Exceeds Expectations （超出预期）
			//else if(this.gemLvTank[15]>=1280) out_dj="G";//...Good
			else if(this.gemLvTank[15]>=1250) out_dj="A";//Acceptable （及格）
			else if(this.gemLvTank[15]>=1200) out_dj="P";//Poor  （不佳）
			else if(this.gemLvTank[15]>=1150) out_dj="D";//Dreadful （差）
			else out_dj="T";//糟糕透了
		}else if(this.gemType=="empowered"){
			if(this.gemLvTank[15]>=1720) out_dj="O";//Outstanding （完美）
			else if(this.gemLvTank[15]>=1700) out_dj="E";//Exceeds Expectations （超出预期）
			//else if(this.gemLvTank[15]>=1280) out_dj="G";//...Good
			else if(this.gemLvTank[15]>=1650) out_dj="A";//Acceptable （及格）
			else if(this.gemLvTank[15]>=1610) out_dj="P";//Poor  （不佳）
			else if(this.gemLvTank[15]>=1600) out_dj="D";//Dreadful （差）
		}
		return out_dj;
	}
}
